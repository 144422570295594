import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import ColorBlock from 'components/ColorBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "maintaining-consistent-and-engaging-digital-interfaces-throughout-ibm-whether-applications-or-experiences-demands-extended-guidance-around-color-usage-the-following-concepts-are-the-foundation-as-we-strive-to-achieve-balance-and-harmony-through-our-user-interface-design"
    }}>{`Maintaining consistent and engaging digital interfaces throughout IBM, whether applications or experiences, demands extended guidance around color usage. The following concepts are the foundation as we strive to achieve balance and harmony through our User Interface design.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Introduction</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Color anatomy</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Implementing color</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Accessibility</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row className="tile--group" mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="RGB color palettes (.ase and .clr)" href="https://www.carbondesignsystem.com/files/IBM_Colors_RGB.zip" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "101.63678877630554%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "ase",
              "title": "ase",
              "src": "/static/5a9c67aa60ffd20d5598081b08a8ebc8/fb070/ase.png",
              "srcSet": ["/static/5a9c67aa60ffd20d5598081b08a8ebc8/d6747/ase.png 288w", "/static/5a9c67aa60ffd20d5598081b08a8ebc8/09548/ase.png 576w", "/static/5a9c67aa60ffd20d5598081b08a8ebc8/fb070/ase.png 1152w", "/static/5a9c67aa60ffd20d5598081b08a8ebc8/21b19/ase.png 1283w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Elements package: Color" href="https://github.com/IBM/carbon-elements/tree/master/packages/colors" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon",
              "title": "github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <br />
    <br />
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`Application of the color palette brings a unified and recognizable consistency
to IBM’s vast array of digital products and interfaces. This consistency is
grounded by a set of well-defined rules on how to work with the Carbon component
library in the context of dark and light themes.`}</p>
    <img {...{
      "src": "/IBM_UI_Screens-6e5da95292e87ff2a49a40d9289fcb09.gif",
      "alt": "Color in UI"
    }}></img>
    <h2 {...{
      "id": "color-anatomy"
    }}>{`Color anatomy`}</h2>
    <p>{`Carbon’s default themes are derived from the IBM Design Language color palette.
The Neutral Gray family is dominant in the default themes, making use of subtle
shifts in value to help organize content into distinct zones.`}</p>
    <p>{`The core Blue family serves as the primary action color across all IBM products
and experiences. Additional colors are used sparingly and purposefully.`}</p>
    <img {...{
      "src": "/color_anatomy_bkg-6da7621826d8f6b4b37bdd51796472ba.svg",
      "alt": "color anatomy"
    }}></img>
    <h2 {...{
      "id": "implementing-color"
    }}>{`Implementing color`}</h2>
    <p>{`Carbon uses `}<em parentName="p">{`tokens`}</em>{` and `}<em parentName="p">{`themes`}</em>{` to manage color. Tokens are role-based, and
themes specify the color values that serve those roles in the UI.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Term`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Definition`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Theme`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The set of unique values assigned to the tokens of a Carbon interface`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Token`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The code identifier for a unique role or set of roles. Tokens are universal and never change across themes.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Role`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The systematic usage(s) of a token. Roles cannot be changed between themes.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Value`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The actual style (i.e. hex code) assigned to a token`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "tokens"
    }}>{`Tokens`}</h3>
    <p>{`Color in Carbon is managed using color tokens. Tokens are a method of
abstracting color by role or usage, independent of the actual color values. For
example, instead of needing to code all instances of input labels in a UI to be
`}<inlineCode parentName="p">{`#565656`}</inlineCode>{`, the `}<em parentName="p">{`form`}</em>{` component in Carbon specifies the token `}<inlineCode parentName="p">{`$text-02`}</inlineCode>{` for
these lables, and that token is specified in a separate theme file to be
`}<inlineCode parentName="p">{`#565656`}</inlineCode>{`. For a different theme, that same `}<inlineCode parentName="p">{`text-02`}</inlineCode>{` token could be mapped to a
different hex value, like `}<inlineCode parentName="p">{`#ffffff`}</inlineCode>{`. Thus, tokens not only allow for more
efficient color updates within a theme, but also enable any UI (or portion of a
UI) built with Carbon to easily switch between different themes.`}</p>
    <p>{`A single token can be associated with multiple roles, but only if the color
value is used consistently across those roles. This allows for uniform color
application across themes while maintaining full styling flexibility.`}</p>
    <p>{`With this system, all Carbon users can create their own themes by assigning new
values to the established color tokens. IBM products should use one of the four
IBM default themes.`}</p>
    <h3 {...{
      "id": "themes"
    }}>{`Themes`}</h3>
    <p>{`Themes serve as an organizational framework for color in Carbon, with each theme
based on a specific primary background color. There are two default “light”
themes and two default “dark” themes. The light themes use White and Gray 10
backgrounds, and the dark themes use Gray 100 and Gray 90 backgrounds. Default
color tokens are provided for each component based on the primary background
color.`}</p>
    <Row className="color-blocks" mdxType="Row">
      <Column colSm={1} colMd={1} colLg={2} offsetLg={4} mdxType="Column">
        <div className="bx--aspect-ratio bx--aspect-ratio--1x1">
  <div className="bx--aspect-ratio--object color-square color-square--white">
    White
  </div>
        </div>
        <div className="bx--caption">Light</div>
      </Column>
      <Column colSm={1} colMd={1} colLg={2} mdxType="Column">
        <div className="bx--aspect-ratio bx--aspect-ratio--1x1">
  <div className="bx--aspect-ratio--object color-square color-square--gray-10">
    Gray 10
  </div>
        </div>
      </Column>
      <Column colSm={1} colMd={1} colLg={2} mdxType="Column">
        <div className="bx--aspect-ratio bx--aspect-ratio--1x1">
  <div className="bx--aspect-ratio--object color-square color-square--gray-100">
    Gray 100
  </div>
        </div>
        <div className="bx--caption">Dark</div>
      </Column>
      <Column colSm={1} colMd={1} colLg={2} mdxType="Column">
        <div className="bx--aspect-ratio bx--aspect-ratio--1x1">
  <div className="bx--aspect-ratio--object color-square color-square--gray-90">
    Gray 90
  </div>
        </div>
      </Column>
    </Row>
    <br />
    <h4 {...{
      "id": "global-background-colors"
    }}>{`Global background colors`}</h4>
    <br />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Theme`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Primary Background`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Token`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Hex value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`White`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Global Background Light`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-background`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorBlock showhex size="xs" mdxType="ColorBlock">{`#ffffff`}</ColorBlock></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Global Background Light`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-background`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorBlock showhex size="xs" mdxType="ColorBlock">{`#f3f3f3`}</ColorBlock></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 90`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Global Background Dark`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-background`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorBlock showhex size="xs" mdxType="ColorBlock">{`#282828`}</ColorBlock></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Global Background Dark`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-background`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorBlock showhex size="xs" mdxType="ColorBlock">{`#171717`}</ColorBlock></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <br />
    <h4 {...{
      "id": "light-themes"
    }}>{`Light themes`}</h4>
    <p>{`Gray 10 components are paired with White backgrounds to make the White theme and
White components with Gray 10 backgrounds to make the Gray 10 theme. Some
components, such as buttons and toggles, share the same colors across both light
themes.`}</p>
    <Row mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="6" mdxType="Column">
  <DoDontExample correct label="Gray 10 dropdown on White background." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1088px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Gray 10 dropdown on White background.",
              "title": "Gray 10 dropdown on White background.",
              "src": "/static/e39f2fd3b3b8698524bb28e259111fca/9eefd/Light_theme_01.png",
              "srcSet": ["/static/e39f2fd3b3b8698524bb28e259111fca/d6747/Light_theme_01.png 288w", "/static/e39f2fd3b3b8698524bb28e259111fca/09548/Light_theme_01.png 576w", "/static/e39f2fd3b3b8698524bb28e259111fca/9eefd/Light_theme_01.png 1088w"],
              "sizes": "(max-width: 1088px) 100vw, 1088px"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
      <Column colMd="4" colLg="6" mdxType="Column">
  <DoDontExample correct label="White dropdown on a Gray 10 background." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1088px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "White dropdown on a Gray 10 background.",
              "title": "White dropdown on a Gray 10 background.",
              "src": "/static/b0f40c28c5434c5a2816ef779316fdeb/9eefd/Light_theme_02.png",
              "srcSet": ["/static/b0f40c28c5434c5a2816ef779316fdeb/d6747/Light_theme_02.png 288w", "/static/b0f40c28c5434c5a2816ef779316fdeb/09548/Light_theme_02.png 576w", "/static/b0f40c28c5434c5a2816ef779316fdeb/9eefd/Light_theme_02.png 1088w"],
              "sizes": "(max-width: 1088px) 100vw, 1088px"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="6" mdxType="Column">
  <DoDontExample correct label="White overflow menu on Gray 10 card." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1088px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "White overflow menu on Gray 10 card.",
              "title": "White overflow menu on Gray 10 card.",
              "src": "/static/b7e7848d2bcce0c0eca3f6537c5962d6/9eefd/Light_theme_03.png",
              "srcSet": ["/static/b7e7848d2bcce0c0eca3f6537c5962d6/d6747/Light_theme_03.png 288w", "/static/b7e7848d2bcce0c0eca3f6537c5962d6/09548/Light_theme_03.png 576w", "/static/b7e7848d2bcce0c0eca3f6537c5962d6/9eefd/Light_theme_03.png 1088w"],
              "sizes": "(max-width: 1088px) 100vw, 1088px"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
      <Column colMd="4" colLg="6" mdxType="Column">
  <DoDontExample label="Avoid use of midtones." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1088px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Avoid use of midtones.",
              "title": "Avoid use of midtones.",
              "src": "/static/a00ab6863dee1c27cbad7b4b2608c1da/9eefd/Light_theme_04.png",
              "srcSet": ["/static/a00ab6863dee1c27cbad7b4b2608c1da/d6747/Light_theme_04.png 288w", "/static/a00ab6863dee1c27cbad7b4b2608c1da/09548/Light_theme_04.png 576w", "/static/a00ab6863dee1c27cbad7b4b2608c1da/9eefd/Light_theme_04.png 1088w"],
              "sizes": "(max-width: 1088px) 100vw, 1088px"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
    </Row>
    <img {...{
      "src": "/Light_theme_sample_revised-db2bb52890da29f3391f480d846b5a38.gif",
      "alt": "light theme examples"
    }}></img>
    <br />
    <h4 {...{
      "id": "dark-themes"
    }}>{`Dark themes`}</h4>
    <p>{`Gray 90 components are paired with Gray 100 backgrounds to make the Gray 100
Theme and Gray 80 components with Gray 90 backgrounds to make the Gray 90 Theme.
Some components, such as buttons and toggles, share the same colors across both
light themes.`}</p>
    <Row mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="6" mdxType="Column">
  <DoDontExample correct label="Gray 90 dropdown on Gray 100 background." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1088px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Gray 10 dropdown on White background.",
              "title": "Gray 10 dropdown on White background.",
              "src": "/static/e63eb052d9ce2b5db376e61cf3488c6a/9eefd/Dark_theme_01_new.png",
              "srcSet": ["/static/e63eb052d9ce2b5db376e61cf3488c6a/d6747/Dark_theme_01_new.png 288w", "/static/e63eb052d9ce2b5db376e61cf3488c6a/09548/Dark_theme_01_new.png 576w", "/static/e63eb052d9ce2b5db376e61cf3488c6a/9eefd/Dark_theme_01_new.png 1088w"],
              "sizes": "(max-width: 1088px) 100vw, 1088px"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
      <Column colMd="4" colLg="6" mdxType="Column">
  <DoDontExample correct label="Gray 80 dropdown on Gray 90 background." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1088px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "White dropdown on a Gray 10 background.",
              "title": "White dropdown on a Gray 10 background.",
              "src": "/static/40d89a91d11e0054e3aeeed564002359/9eefd/Dark_theme_02_new.png",
              "srcSet": ["/static/40d89a91d11e0054e3aeeed564002359/d6747/Dark_theme_02_new.png 288w", "/static/40d89a91d11e0054e3aeeed564002359/09548/Dark_theme_02_new.png 576w", "/static/40d89a91d11e0054e3aeeed564002359/9eefd/Dark_theme_02_new.png 1088w"],
              "sizes": "(max-width: 1088px) 100vw, 1088px"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="6" mdxType="Column">
  <DoDontExample correct label="Gray 70 is sometimes used when compound components are being applied, such as an overflow menu." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1088px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "White overflow menu on Gray 10 card.",
              "title": "White overflow menu on Gray 10 card.",
              "src": "/static/35627308af7187849970a47a0b72f238/9eefd/Dark_theme_03_new.png",
              "srcSet": ["/static/35627308af7187849970a47a0b72f238/d6747/Dark_theme_03_new.png 288w", "/static/35627308af7187849970a47a0b72f238/09548/Dark_theme_03_new.png 576w", "/static/35627308af7187849970a47a0b72f238/9eefd/Dark_theme_03_new.png 1088w"],
              "sizes": "(max-width: 1088px) 100vw, 1088px"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
      <Column colMd="4" colLg="6" mdxType="Column">
  <DoDontExample label="Do not apply components that are darker than the background unless using high-contrast mode." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1088px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Do not apply components that are darker than the background unless using high-contrast mode.",
              "title": "Do not apply components that are darker than the background unless using high-contrast mode.",
              "src": "/static/2e77593918925c7ec25e07cf2a9b7cb5/9eefd/Dark_theme_04_new.png",
              "srcSet": ["/static/2e77593918925c7ec25e07cf2a9b7cb5/d6747/Dark_theme_04_new.png 288w", "/static/2e77593918925c7ec25e07cf2a9b7cb5/09548/Dark_theme_04_new.png 576w", "/static/2e77593918925c7ec25e07cf2a9b7cb5/9eefd/Dark_theme_04_new.png 1088w"],
              "sizes": "(max-width: 1088px) 100vw, 1088px"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
    </Row>
    <img {...{
      "src": "/Dark_theme_sample_revised-7a722443efb1bdbfb103e00ac307a915.gif",
      "alt": "dark theme examples"
    }}></img>
    <br />
    <h4 {...{
      "id": "high-contrast"
    }}>{`High contrast`}</h4>
    <p>{`In some cases, it is helpful to apply light components to dark backgrounds or
dark components to light backgrounds. This technique is useful to focus
attention or create visual tension.`}</p>
    <Row mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="6" mdxType="Column">
  <DoDontExample correct label=" " mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1088px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "High contrast example in a light theme.",
              "title": "High contrast example in a light theme.",
              "src": "/static/b59bd9a10c9c2e75cfafcaa02dcd6474/9eefd/High_contrast_01.png",
              "srcSet": ["/static/b59bd9a10c9c2e75cfafcaa02dcd6474/d6747/High_contrast_01.png 288w", "/static/b59bd9a10c9c2e75cfafcaa02dcd6474/09548/High_contrast_01.png 576w", "/static/b59bd9a10c9c2e75cfafcaa02dcd6474/9eefd/High_contrast_01.png 1088w"],
              "sizes": "(max-width: 1088px) 100vw, 1088px"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
      <Column colMd="4" colLg="6" mdxType="Column">
  <DoDontExample correct label=" " mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1088px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "High contrast example in a dark theme.",
              "title": "High contrast example in a dark theme.",
              "src": "/static/39c592a269815d66822da661d04899c2/9eefd/High_contrast_02.png",
              "srcSet": ["/static/39c592a269815d66822da661d04899c2/d6747/High_contrast_02.png 288w", "/static/39c592a269815d66822da661d04899c2/09548/High_contrast_02.png 576w", "/static/39c592a269815d66822da661d04899c2/9eefd/High_contrast_02.png 1088w"],
              "sizes": "(max-width: 1088px) 100vw, 1088px"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
    </Row>
    <h3 {...{
      "id": "interaction-states"
    }}>{`Interaction states`}</h3>
    <p>{`In addition to the enabled-state tokens above, the five other primary
interaction states have been defined and tokenized for each default theme. For
values between Black and 70, interaction gets lighter, and for values between 60
and White, interaction gets darker.`}</p>
    <h4 {...{
      "id": "hover"
    }}>{`Hover`}</h4>
    <p>{`Hover states have their own tokens. In the IBM default themes, these colors fall
outside of the core palette steps and act as “half steps” between two adjacent
colors. The White theme shares the same hover value as the Gray 10 theme; the
two dark theme backgrounds share a hover value as well.`}</p>
    <h4 {...{
      "id": "active"
    }}>{`Active`}</h4>
    <p>{`The active state is two full steps lighter for values between 100 and 70 and two
full steps darker for values between 60 and 10. For example, the Blue 60 active
state is Blue 80. The exception is that White shares the same active state as
Gray 10, and Black shares the same active state as Gray 100.`}</p>
    <h4 {...{
      "id": "selected"
    }}>{`Selected`}</h4>
    <p>{`The selected state is one full step lighter for values between 100 and 70, and
one full step darker for values between 60 and 10. For example, the Blue 60
selected state is Blue 70. The exception is that White shares the same selected
state as Gray 10, and Black shares the same selected state as Gray 100.`}</p>
    <h4 {...{
      "id": "focus"
    }}>{`Focus`}</h4>
    <p>{`The focus state usually appears as a Blue 60 border in the light theme and as a
White border in the dark theme.`}</p>
    <h4 {...{
      "id": "disabled"
    }}>{`Disabled`}</h4>
    <p>{`Disabled components are unavailable for interaction, so they don’t receive hover
or focus and are not subject to WC3 contrast compliance standards. For the dark
themes, disabled values are two full steps lighter than their respective
background. For light themes, they are two full steps darker. The exception is
that the White theme disabled value is Gray 30.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "51.78571428571428%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "interactive states light",
        "title": "interactive states light",
        "src": "/static/c31ab0ff67b7b2760ca015d3ec2021ff/fb070/Interactive_states_light_new.png",
        "srcSet": ["/static/c31ab0ff67b7b2760ca015d3ec2021ff/d6747/Interactive_states_light_new.png 288w", "/static/c31ab0ff67b7b2760ca015d3ec2021ff/09548/Interactive_states_light_new.png 576w", "/static/c31ab0ff67b7b2760ca015d3ec2021ff/fb070/Interactive_states_light_new.png 1152w", "/static/c31ab0ff67b7b2760ca015d3ec2021ff/fb104/Interactive_states_light_new.png 1728w", "/static/c31ab0ff67b7b2760ca015d3ec2021ff/8fefe/Interactive_states_light_new.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "51.78571428571428%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "interactive states dark",
        "title": "interactive states dark",
        "src": "/static/07e85689bc8936e0d1e8864bec0115d7/fb070/Interactive_states_dark_new.png",
        "srcSet": ["/static/07e85689bc8936e0d1e8864bec0115d7/d6747/Interactive_states_dark_new.png 288w", "/static/07e85689bc8936e0d1e8864bec0115d7/09548/Interactive_states_dark_new.png 576w", "/static/07e85689bc8936e0d1e8864bec0115d7/fb070/Interactive_states_dark_new.png 1152w", "/static/07e85689bc8936e0d1e8864bec0115d7/fb104/Interactive_states_dark_new.png 1728w", "/static/07e85689bc8936e0d1e8864bec0115d7/8fefe/Interactive_states_dark_new.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "accessibility"
    }}>{`Accessibility`}</h2>
    <p>{`Using various forms of contrast is the most important consideration when making
user-friendly color and interface choices. Awareness of standards and best
practices is the key to accessible color selection.`}</p>
    <p>{`The color families in the IBM palette contain ten values from 10 to 100. White
and Black sit outside those values. Black text is WCAG AA accessible on colors
ranging from 10 to 50. White text is accessible on colors from 60 to 100.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "13.571428571428571%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Digital accessibility",
        "title": "Digital accessibility",
        "src": "/static/dd310e3baf7021e856e4108c9c0731fb/fb070/accessibility.png",
        "srcSet": ["/static/dd310e3baf7021e856e4108c9c0731fb/d6747/accessibility.png 288w", "/static/dd310e3baf7021e856e4108c9c0731fb/09548/accessibility.png 576w", "/static/dd310e3baf7021e856e4108c9c0731fb/fb070/accessibility.png 1152w", "/static/dd310e3baf7021e856e4108c9c0731fb/fb104/accessibility.png 1728w", "/static/dd310e3baf7021e856e4108c9c0731fb/8fefe/accessibility.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <p>{`Beyond black and white, the IBM color palette provides a range of accessible
combinations. Subtracting the foreground value from the background value (or
vice versa) helps determine whether that color combination meets the WCAG AA
contrast ratio success criteria. If the difference between two values is 50 or
greater, the colors are accessible. Anything below a difference of 50 may fail
accessibility standards.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "16.249999999999996%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Digital accessibility",
        "title": "Digital accessibility",
        "src": "/static/394781b7d7d34c883c87e4b9e901ccfc/fb070/accessibility_02.png",
        "srcSet": ["/static/394781b7d7d34c883c87e4b9e901ccfc/d6747/accessibility_02.png 288w", "/static/394781b7d7d34c883c87e4b9e901ccfc/09548/accessibility_02.png 576w", "/static/394781b7d7d34c883c87e4b9e901ccfc/fb070/accessibility_02.png 1152w", "/static/394781b7d7d34c883c87e4b9e901ccfc/fb104/accessibility_02.png 1728w", "/static/394781b7d7d34c883c87e4b9e901ccfc/8fefe/accessibility_02.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <p><em parentName="p">{`Blue color family, 60 background`}</em></p>
    <p>{`For a complete look at color-related accessibility topics in Carbon, visit the
`}<a parentName="p" {...{
        "href": "/guidelines/accessibility/color"
      }}>{`Accessibility`}</a>{` section.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      